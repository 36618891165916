@import '~stylewhere/theme';

// Modifica variabili di react-datepicker/src/stylesheets/variables.scss
$datepicker__font-family: $sw-font-family;
$datepicker__selected-color: $sw-secondary;

@import '~react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker__close-icon {
  padding: 0;
  position: absolute;
  top: 0;
  right: 20px;

  &::after {
    height: 24px;
    width: 24px;
    padding: 0;
    font-size: 24px;
  }
}
